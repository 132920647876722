<template>
    <div id="activityIndexPage">
      <div class="container">
        <div class="section section-1" v-if="detail.state === 2">
          <div class="bg"></div>
          <div class="content">
            <div class="date">活动时间：{{ detail.startTime }}～{{ detail.endTime }}</div>
            <button type="button" class="submit-btn" @click="getCoupons()">受領</button>
          </div>
        </div>
        <div class="section section-2" v-if="detail.state === 1">
          <i class="icon icon-fail"></i>
          <div class="tip">キャンペーンが終了しました</div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ActivityIndex',
  components: {},
  data () {
    return {
      activityId: '',
      id: '',
      detail: {},
      isBind: 0, // 0 没有绑定 1 绑定
      isSubmit: true
    };
  },
  created () {
  },
  computed: {
    ...mapGetters(['personInfo'])
  },
  mounted () {
    this.isBind = this.personInfo.crediCardState;
    const { query: { activityId, id } } = this.$route;
    this.activityId = activityId;
    this.id = id;
    // 获取详情数据
    this.getDetail();
  },
  watch: {},
  methods: {
    getDetail () {
      let { activityId, detail } = this;
      this.$http.get('mine/message/buy', {
        params: {
          activityId
        }
      }).then((res) => {
        detail = res.data || {};
        const { meta } = this.$route;
        meta.title = detail.state === 1 ? 'キャンペーンが終了しました' : detail.name;
        this.detail = detail;
      });
    },

    /* 领取提送礼包 */
    getCoupons () {
      const { activityId, id } = this;
      // if (this.detail.state === 1) {
      //   this.$router.push({ name: 'DelIndex' });
      //   return false;
      // }

      if (this.isSubmit) {
        if (this.isBind === 0) {
          this.bindPay({
            success: () => {
              this.bindSuccess(activityId, id);
            }
          });
        } else if (this.isBind === 2) {
          this.$utils.toast({
            message: 'お客様のクレジットカードが期限切れになりました。クレジットカードの情報を更新してください。',
            onClose: () => {
              this.$router.push({ name: 'SettingIndex', params: { type: 1 } });
            }
          });
        } else {
          this.payLift(activityId, id);
        }
        this.isSubmit = false;
      }

    },

    /* 支付礼包 */
    payLift (activityId, id) {
      const { $router } = this;
      this.$http.get('mine/receiveGigt', {
        params: {
          activityGiftId: activityId,
          userMessageId: id
        }
      }).then(async (res) => {
        const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
        elepay.handleCharge(res.data)
          .then((result) => {
            if (result.type === 'cancel') {
              console.log('取消');
            } else if (result.type === 'success') {
              this.timeSuccess(res.data.orderNo);
            }
          })
          .catch((err) => {
            console.log('支付异常:', err);
            $router.push({
              name: 'VideoBuyResult',
              params: {
                type: 'faild'
              }
            });
          });
      });
    },

    /* 时间轮询 */
    timeSuccess (orderNo) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$http.get('mine/orderStatus', { params: { orderNo } }).then((res) => {
          this.$router.replace({ name: 'MemberIndex' });
        }).catch(() => {
          this.timeSuccess(orderNo);
        });
      }, 1000);
    },

    /* 绑定信用卡 */
    bindPay (obj) {
      this.$http.get('set/changeOrBindingCard', { showLoading: true }).then(async (res) => {
        const elepay = await this.loadElepay('pk_live_90f822a7a681e4670c3d3');
        elepay.handleSource(res.data).then((result) => {
          // ① 正常処理
          if (result.type === 'cancel') {
            console.log('取消支付');
          } else if (result.type === 'success') {
            obj.success();
          }
        }).catch((err) => {
          console.log(err);
        });
      });
    },

    bindSuccess (activityId, id) {
      let timer;
      timer = setTimeout(() => {
        if (this.isBind === 0) {
          this.$http.get('mine/information').then((res) => {
            this.isBind = res.data.crediCardState;
          });
          this.bindSuccess(activityId, id);
        } else {
          clearTimeout(timer);
          this.payLift(activityId, id);
        }
      }, 1000);
    }


  }
};
</script>

<style scoped lang="less">
  #activityIndexPage {
    .container {
      .section {
        text-align: center;

        &.section-1 {
          .bg {
            height: 658px;
            margin-bottom: 38px;
            background: url("~@/assets/images/bg_15.png") center / contain no-repeat;
          }

          .content {
            .date {
              font-size: 24px;
              line-height: 32px;
              opacity: 0.7;
            }

            .submit-btn {
              width: 694px;
              margin-top: 98px;
            }
          }
        }

        &.section-2 {
          padding-top: 62px;
          padding-bottom: 62px;

          .icon-fail {
            width: 200px;
            height: 200px;
            margin-bottom: 44px;
            background-image: url("~@/assets/images/icon_fail_01.png");
          }

          .tip {
            font-size: 36px;
            line-height: 48px;
            color: #fff;
          }
        }
      }
    }

    .popup-bindPay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 672px;
      height: 83%;
      max-height: 1118px;

      .close {
        position: fixed;
        top: 3px;
        right: 5px;
        width: 50px;
        height: 50px;
        font-size: 25px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      .content {
        width: 360px;
        font-size: 30px;
        font-weight: bold;
        line-height: 86px;
        text-align: center;
      }

      .bottom {
        position: fixed;
        bottom: 39px;
        justify-self: flex-end;
        width: 80%;

        .btn {
          width: 100%;
          border-radius: 15px;
        }
      }
    }

    .popup-coupons {
      width: 670px;
      overflow: hidden;
      border-radius: 54px 54px 0 0;

      .popup-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 162px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .popup-title {
          padding-right: 46px;
          padding-left: 46px;
          font-size: 36px;
          line-height: 46px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        max-height: 700px;
        padding: 48px 36px 64px;
        margin-top: -1px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .tip {
          margin-bottom: 70px;
          font-size: 24px;
          line-height: 32px;
          opacity: 0.7;
        }

        .list {
          height: 100%;
          overflow-y: auto;

          .list-item {
            display: flex;
            align-items: center;
            height: 142px;
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: #fff;
            border-radius: 16px;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .left {
              flex: 1;
              align-self: stretch;
              padding: 16px 24px;
              border-right: 2px dashed #ccc;

              .title {
                margin-bottom: 10px;
                font-size: 36px;
                font-weight: bold;
                line-height: 46px;
                color: #333;
              }

              .tip {
                font-size: 24px;
                line-height: 32px;
                color: #666;
              }
            }

            .right {
              flex-shrink: 0;
              width: 172px;
              padding-right: 8px;
              padding-left: 8px;
              font-size: 52px;
              font-weight: bold;
              color: #ee3d19;
              text-align: center;
            }
          }
        }

        .content-footer {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          margin-top: 40px;

          .btn {
            height: 82px;
            border-radius: 16px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);

            &.btn-cancel {
              width: 212px;
              margin-right: 20px;
              font-size: 26px;
              color: rgba(255, 255, 255, 0.8);
              background-color: #ff6854;
              border-radius: 16px;
            }

            &.btn-confirm {
              flex: 1;
              font-size: 32px;
              font-weight: bold;
              color: #333;
              background-color: #fac26c;
            }
          }
        }
      }
    }
  }
</style>